import Constant from '@/const';

const axios = require('axios');
const moment = require('moment');

export default {
  namespaced: true,
  state: {
    firstAssessment: {
      diseaseHistory: null,
      dialysisTransplantation: null,
      physicalCheckup: null,
      etiologyDiagnosis: null,
      labSupport: null,
      timestamps: null,
    },
    assessment: {
      mainComplaint: null,
      physicalCheckup: null,
      firstNutritionScreening: null,
      nurseryDiagnosis: null,
      timestampsNurse: null,
      timestampsDoctor: null,
    },
    monitoring: null,
  },
  getters: {
    firstAssessment: (state) => state.firstAssessment,
    assessment: (state) => state.assessment,
    monitoring: (state) => state.monitoring,
  },
  mutations: {
    saveAssessment(state, payload) {
      if (payload.section === '') {
        state[payload.key] = payload.data;
      } else {
        state[payload.key][payload.section] = payload.data;
      }
    },
    removeAssessment(state) {
      state.firstAssessment = {
        diseaseHistory: null,
        dialysisTransplantation: null,
        physicalCheckup: null,
        etiologyDiagnosis: null,
        labSupport: null,
      };
      state.assessment = {
        mainComplaint: null,
        physicalCheckup: null,
        firstNutritionScreening: null,
        nurseryDiagnosis: null,
      };
      state.monitoring = null;
    },
  },
  actions: {
    postFirstAssessment({ getters, rootGetters }) {
      const postPayload = {
        isRefference: rootGetters.selectedPatient.isReferral || false,
        detail: [
          {
            disease_history: {
              hypertens:
                getters.firstAssessment.diseaseHistory === null
                  ? ''
                  : getters.firstAssessment.diseaseHistory[0],
              diabetes:
                getters.firstAssessment.diseaseHistory === null
                  ? ''
                  : getters.firstAssessment.diseaseHistory[1],
              urinary_tract_stone:
                getters.firstAssessment.diseaseHistory === null
                  ? ''
                  : getters.firstAssessment.diseaseHistory[2],
              urinary_tract_operation:
                getters.firstAssessment.diseaseHistory === null
                  ? ''
                  : getters.firstAssessment.diseaseHistory[3],
              urinary_tract_infection:
                getters.firstAssessment.diseaseHistory === null
                  ? ''
                  : getters.firstAssessment.diseaseHistory[4],
              swollen_body:
                getters.firstAssessment.diseaseHistory === null
                  ? ''
                  : getters.firstAssessment.diseaseHistory[5],
              bloody_urinary:
                getters.firstAssessment.diseaseHistory === null
                  ? ''
                  : getters.firstAssessment.diseaseHistory[6],
              another_kidney_disease:
                getters.firstAssessment.diseaseHistory === null
                  ? ''
                  : getters.firstAssessment.diseaseHistory[7],
              another_disease:
                getters.firstAssessment.diseaseHistory === null
                  ? ''
                  : getters.firstAssessment.diseaseHistory[8],
              nefrotocsic_consume: {
                since:
                  getters.firstAssessment.diseaseHistory === null
                    ? ''
                    : getters.firstAssessment.diseaseHistory[9],
                detail: '',
              },
            },
            transplantations: {
              first_dialisis:
                getters.firstAssessment.dialysisTransplantation[0] === ''
                  ? ''
                  : getters.firstAssessment.dialysisTransplantation[0],
              capd: {
                status:
                  getters.firstAssessment.dialysisTransplantation[1] !== '',
                date:
                  getters.firstAssessment.dialysisTransplantation[1] === ''
                    ? ''
                    : getters.firstAssessment.dialysisTransplantation[1],
              },
              transplantation: {
                status:
                  getters.firstAssessment.dialysisTransplantation[2] !== '',
                date:
                  getters.firstAssessment.dialysisTransplantation[2] === ''
                    ? ''
                    : getters.firstAssessment.dialysisTransplantation[2],
              },
            },
            first_physical_checkup: {
              general_condition:
                getters.firstAssessment.physicalCheckup.generalCondition,
              awarness: getters.firstAssessment.physicalCheckup.conciousness,
              weight: Number(
                getters.firstAssessment.physicalCheckup.bodyWeight,
              ),
              height: Number(
                getters.firstAssessment.physicalCheckup.bodyHeight,
              ),
              td: [
                Number(getters.firstAssessment.physicalCheckup.td[0]),
                Number(getters.firstAssessment.physicalCheckup.td[1]),
              ],
              temperature: Number(
                getters.firstAssessment.physicalCheckup.temperature,
              ),
              pulse: {
                freq: Number(
                  getters.firstAssessment.physicalCheckup.pulse.detail,
                ),
                type: getters.firstAssessment.physicalCheckup.pulse.type === 0,
              },
              breath: {
                freq: Number(
                  getters.firstAssessment.physicalCheckup.respiration.detail,
                ),
                type: getters.firstAssessment.physicalCheckup.respiration.type,
              },
              jvp:
                getters.firstAssessment.physicalCheckup.jugularVeinPressure ===
                0,
              conjunctiva: {
                anemia: getters.firstAssessment.physicalCheckup.anemia === 0,
                sklera: getters.firstAssessment.physicalCheckup.sklere === 0,
              },
              heart: {
                kardionegali:
                  getters.firstAssessment.physicalCheckup.cardiomegaly === 0,
                bising: getters.firstAssessment.physicalCheckup.noisy === 0,
              },
              lung: {
                sonor: getters.firstAssessment.physicalCheckup.sonor === 0,
                wheezing:
                  getters.firstAssessment.physicalCheckup.wheezing === 0,
                ronchi:
                  getters.firstAssessment.physicalCheckup.ronchi.type === 0,
              },
              abdomen: {
                hepatomegali:
                  getters.firstAssessment.physicalCheckup.hepatomegaly === 0,
                splenomegaili:
                  getters.firstAssessment.physicalCheckup.splenomegaly === 0,
                aseites: getters.firstAssessment.physicalCheckup.ascites === 0,
              },
              extremities: getters.firstAssessment.physicalCheckup.edema === 0,
              photo_thorax:
                getters.firstAssessment.physicalCheckup.lastSection[0] === ''
                  ? ''
                  : getters.firstAssessment.physicalCheckup.lastSection[0],
              ekg:
                getters.firstAssessment.physicalCheckup.lastSection[1] === ''
                  ? ''
                  : getters.firstAssessment.physicalCheckup.lastSection[1],
              bno_ivp:
                getters.firstAssessment.physicalCheckup.lastSection[2] === ''
                  ? ''
                  : getters.firstAssessment.physicalCheckup.lastSection[2],
              usg:
                getters.firstAssessment.physicalCheckup.lastSection[3] === ''
                  ? ''
                  : getters.firstAssessment.physicalCheckup.lastSection[3],
              renogram:
                getters.firstAssessment.physicalCheckup.lastSection[4] === ''
                  ? ''
                  : getters.firstAssessment.physicalCheckup.lastSection[4],
              pa_kidney_biopsy:
                getters.firstAssessment.physicalCheckup.lastSection[5] === ''
                  ? ''
                  : getters.firstAssessment.physicalCheckup.lastSection[5],
              ct_scan:
                getters.firstAssessment.physicalCheckup.lastSection[6] === ''
                  ? ''
                  : getters.firstAssessment.physicalCheckup.lastSection[6],
              arteriography:
                getters.firstAssessment.physicalCheckup.lastSection[7] === ''
                  ? ''
                  : getters.firstAssessment.physicalCheckup.lastSection[7],
              urine_culture:
                getters.firstAssessment.physicalCheckup.lastSection[8] === ''
                  ? ''
                  : getters.firstAssessment.physicalCheckup.lastSection[8],
              lab_support:
                getters.firstAssessment.labSupport === null
                  ? {
                      hemogoblin: '',
                      hematocrit: '',
                      leukocytes: '',
                      platelets: '',
                      type_count: '',
                      ureum: '',
                      creatinine: '',
                      uric_acid: '',
                      got: '',
                      gpt: '',
                      hbsag: '',
                      ct_bt: '',
                      complete_urine: '',
                      cct: '',
                      anti_hcv: '',
                    }
                  : {
                      hemogoblin: getters.firstAssessment.labSupport[0],
                      hematocrit: getters.firstAssessment.labSupport[1],
                      leukocytes: getters.firstAssessment.labSupport[2],
                      platelets: getters.firstAssessment.labSupport[3],
                      type_count: getters.firstAssessment.labSupport[4],
                      ureum: getters.firstAssessment.labSupport[5],
                      creatinine: getters.firstAssessment.labSupport[6],
                      uric_acid: getters.firstAssessment.labSupport[7],
                      got: getters.firstAssessment.labSupport[8],
                      gpt: getters.firstAssessment.labSupport[9],
                      hbsag: getters.firstAssessment.labSupport[10],
                      ct_bt: getters.firstAssessment.labSupport[11],
                      complete_urine: getters.firstAssessment.labSupport[12],
                      cct: getters.firstAssessment.labSupport[13],
                      anti_hcv: getters.firstAssessment.labSupport[14],
                    },
            },
            etiological_diagnosis:
              getters.firstAssessment.etiologyDiagnosis === ''
                ? ''
                : getters.firstAssessment.etiologyDiagnosis,
            timestamps: {
              created_by: rootGetters.userLoggedIn.id,
            },
          },
        ],
      };
      if (rootGetters.userLoggedIn.role.primary.toLowerCase() === 'casemix') {
        postPayload.detail[0] = {
          ...postPayload.detail[0],
          timestamps: {
            created_by: getters.firstAssessment.timestamps.created_by,
            corrected_by: rootGetters.userLoggedIn.id,
            corrected_at: moment().format(),
          },
        };
      }
      return axios.post(
        Constant.apiUrl
          .concat('/patient/poly/9/1/')
          .concat(rootGetters.selectedPatient.id_queue)
          .concat('/')
          .concat(rootGetters.userLoggedIn.id),
        postPayload,
      );
    },
    postAssessmentNurse({ getters, rootGetters }) {
      const postPayload = {
        isRefference: rootGetters.selectedPatient.isReferral || false,
        detail: [
          {
            room_no: 'aisyiah10',
            machine_no: 'e15',
            dialisiser_type: 'oke berhasil',
            n_r: 'n dan r',
            medicine_alergic: {
              type: false,
              detail: '',
            },
            health_service: {
              primary_complaint:
                getters.assessment.mainComplaint.complaint.type.map((data) => {
                  return {
                    type: data,
                    detail:
                      data === '3'
                        ? getters.assessment.mainComplaint.complaint.detail
                        : '',
                  };
                }),
              pain: {
                type: getters.assessment.mainComplaint.pain === 1,
                level:
                  getters.assessment.mainComplaint.pain === 1
                    ? getters.assessment.mainComplaint.intensity.level
                    : 0,
                location:
                  getters.assessment.mainComplaint.pain === 1
                    ? getters.assessment.mainComplaint.location
                    : '',
                time: {
                  duration:
                    getters.assessment.mainComplaint.pain === 1
                      ? Number(getters.assessment.mainComplaint.time.duration)
                      : '',
                  type:
                    getters.assessment.mainComplaint.pain === 1
                      ? Number(getters.assessment.mainComplaint.time.type)
                      : '',
                },
                type2:
                  getters.assessment.mainComplaint.pain === 1
                    ? getters.assessment.mainComplaint.intensity.type
                    : 0,
              },
            },
            physical_checkup: {
              general_condition: {
                type: getters.assessment.physicalCheckup.generalCondition.value,
                detail:
                  getters.assessment.physicalCheckup.generalCondition.detail,
              },
              blood_pressure: [
                Number(getters.assessment.physicalCheckup.bloodPressure[0]),
                Number(getters.assessment.physicalCheckup.bloodPressure[1]),
              ],
              awareness: {
                type:
                  getters.assessment.physicalCheckup.conciousness.value === 1,
                detail: getters.assessment.physicalCheckup.conciousness.detail,
              },
              pulse: {
                type: getters.assessment.physicalCheckup.pulse.value === 1,
                freq: getters.assessment.physicalCheckup.pulse.detail,
              },
              respiration: {
                type: getters.assessment.physicalCheckup.respiration.value,
                freq: Number(
                  getters.assessment.physicalCheckup.respiration.detail,
                ),
              },
              conjunctiva: {
                type: getters.assessment.physicalCheckup.conjunctiva.value,
                detail: getters.assessment.physicalCheckup.conjunctiva.detail,
              },
              extrimities: getters.assessment.physicalCheckup.extrimities,
              weight: {
                dry_weight: Number(
                  getters.assessment.physicalCheckup.bodyWeight,
                ),
                pre_hd: Number(getters.assessment.physicalCheckup.weightPreHd),
                post_hd: Number(
                  getters.assessment.physicalCheckup.weightPostHd,
                ),
              },
              vascular_access: {
                type: Number(getters.assessment.physicalCheckup.vascularAccess),
              },
              hd_catheter: [
                {
                  type:
                    getters.assessment.physicalCheckup.hdCatheter === ''
                      ? ''
                      : Number(getters.assessment.physicalCheckup.hdCatheter),
                },
              ],
              fall_risk: {
                no_1: getters.assessment.physicalCheckup.fallHistory,
                no_2: getters.assessment.physicalCheckup.medicDiagnosis,
                no_3: getters.assessment.physicalCheckup.toolsHelper,
                no_4: getters.assessment.physicalCheckup.therapy,
                no_5: getters.assessment.physicalCheckup.wayOfWalking,
                no_6: getters.assessment.physicalCheckup.mentalStatus,
                summary: {
                  total_score: 0,
                  type: 0,
                },
              },
            },
            secondary_checkup:
              getters.assessment.firstNutritionScreening.support,
            initial_nutrition: {
              no1: {
                type: getters.assessment.firstNutritionScreening.weightDrop
                  .type,
                score:
                  getters.assessment.firstNutritionScreening.weightDrop.score,
              },
              no2:
                getters.assessment.firstNutritionScreening.foodIntakeDrop === 1,
            },
            psychosocial_history: {
              no1: getters.assessment.firstNutritionScreening.tradition === 1,
              no2: {
                type:
                  getters.assessment.firstNutritionScreening
                    .obstacleOfCommunication.type === 1,
                detail:
                  getters.assessment.firstNutritionScreening
                    .obstacleOfCommunication.type === 1
                    ? getters.assessment.firstNutritionScreening
                        .obstacleOfCommunication.detail
                    : '',
              },
              no3: {
                type:
                  getters.assessment.firstNutritionScreening.nurseAtHome
                    .type === 1,
                detail:
                  getters.assessment.firstNutritionScreening.nurseAtHome
                    .type === 1
                    ? getters.assessment.firstNutritionScreening.nurseAtHome
                        .detail
                    : '',
              },
              no4:
                getters.assessment.firstNutritionScreening.presentCondition !==
                null
                  ? [
                      {
                        type: getters.assessment.firstNutritionScreening
                          .presentCondition,
                      },
                    ]
                  : [],
            },
            praying_ability: {
              no1: {
                type: getters.assessment.firstNutritionScreening
                  .obligatoryPrayer.value,
                detail:
                  getters.assessment.firstNutritionScreening.obligatoryPrayer
                    .value === 2
                    ? getters.assessment.firstNutritionScreening
                        .obligatoryPrayer.detail
                    : '',
              },
              no2: getters.assessment.firstNutritionScreening.wudhu,
              no3: getters.assessment.firstNutritionScreening.sholat,
            },
            nursery_diagnostic: {
              complaint_type:
                getters.assessment.nurseryDiagnosis.diagnosis === ''
                  ? ''
                  : getters.assessment.nurseryDiagnosis.diagnosis.type.map(
                      (data) => {
                        return {
                          type: data,
                          detail:
                            data === 7
                              ? getters.assessment.nurseryDiagnosis.diagnosis
                                  .other
                              : '',
                        };
                      },
                    ),
              nursery_intervention_type:
                getters.assessment.nurseryDiagnosis.nurseryIntervention === ''
                  ? ''
                  : getters.assessment.nurseryDiagnosis.nurseryIntervention.type.map(
                      (data) => {
                        let detail = '';
                        if (data === 9) {
                          detail =
                            getters.assessment.nurseryDiagnosis
                              .nurseryIntervention.healthEducation;
                        } else if (data === 12) {
                          detail =
                            getters.assessment.nurseryDiagnosis
                              .nurseryIntervention.other;
                        }
                        return {
                          type: data,
                          detail,
                        };
                      },
                    ),
              collaboration_intervention:
                getters.assessment.nurseryDiagnosis
                  .collaborationIntervention === ''
                  ? ''
                  : getters.assessment.nurseryDiagnosis.collaborationIntervention.map(
                      (data) => {
                        return {
                          type: data,
                        };
                      },
                    ),
            },

            nurses_on_duty: !getters.assessment.nurseryDiagnosis.dataNurse
              ? ''
              : getters.assessment.nurseryDiagnosis.dataNurse.allSelected.map(
                  (data) => {
                    return data.id || data._id;
                  },
                ),
            timestamps: {
              created_by: rootGetters.userLoggedIn.id,
            },
            nurse_access_info: !getters.assessment.nurseryDiagnosis.dataNurse
              ? ''
              : getters.assessment.nurseryDiagnosis.dataNurse.select.id ||
                getters.assessment.nurseryDiagnosis.dataNurse.select._id,
          },
        ],
      };

      if (rootGetters.userLoggedIn.role.primary.toLowerCase() === 'casemix') {
        postPayload.detail[0] = {
          ...postPayload.detail[0],
          timestamps: {
            created_by: getters.assessment.timestampsNurse.created_by_id,
            corrected_by: rootGetters.userLoggedIn.id,
            corrected_at: moment().format(),
          },
        };
      }
      return axios.post(
        Constant.apiUrl
          .concat('/patient/poly/9/2/')
          .concat(rootGetters.selectedPatient.id_queue)
          .concat('/2'),
        postPayload,
      );
    },
    postAssessmentDoctor({ getters, rootGetters }) {
      const postPayload = {
        isRefference: rootGetters.selectedPatient.isReferral || false,
        medical_instruction: [
          {
            complaint:
              getters.assessment.nurseryDiagnosis.medicInstruction.complaint,
            supporting_checkup:
              getters.assessment.nurseryDiagnosis.medicInstruction
                .supportCheckup,
            physical_checkup:
              getters.assessment.nurseryDiagnosis.medicInstruction
                .physicalCheckup,
            diagnostic:
              getters.assessment.nurseryDiagnosis.medicInstruction.diagnosis
                .filter((arr) => arr !== null)
                .map((data) => {
                  return data.icd && data.icd.id
                    ? {
                        _id: data.icd.id,
                        value: data.icd.value,
                        text: data.icd.text,
                        detail: data.detail,
                      }
                    : {
                        detail: data.detail,
                      };
                }),
            follow_up:
              getters.assessment.nurseryDiagnosis.medicInstruction.follow_up
                .filter((arr) => arr !== null)
                .map((data) => {
                  return data.icd && data.icd.id
                    ? {
                        _id: data.icd.id,
                        value: data.icd.value,
                        text: data.icd.text,
                        detail: data.detail,
                      }
                    : {
                        detail: data.detail,
                      };
                }),
            plan: getters.assessment.nurseryDiagnosis.medicInstruction.planning,
            recipe_hd:
              getters.assessment.nurseryDiagnosis.medicInstruction.hdRecipe
                .type === ''
                ? []
                : [
                    {
                      type: getters.assessment.nurseryDiagnosis.medicInstruction
                        .hdRecipe.type,
                      detail:
                        getters.assessment.nurseryDiagnosis.medicInstruction
                          .hdRecipe.type === 5
                          ? getters.assessment.nurseryDiagnosis.medicInstruction
                              .hdRecipe.detail
                          : '',
                    },
                  ],
            td: Number(getters.assessment.nurseryDiagnosis.medicInstruction.td),
            qb: Number(getters.assessment.nurseryDiagnosis.medicInstruction.qb),
            qd: Number(getters.assessment.nurseryDiagnosis.medicInstruction.qd),
            uf_goal: Number(
              getters.assessment.nurseryDiagnosis.medicInstruction.ufGoal,
            ),
            dialysate: {
              acetate:
                getters.assessment.nurseryDiagnosis.medicInstruction.dialysate
                  .acetate.isChecked,
              bicarbonate:
                getters.assessment.nurseryDiagnosis.medicInstruction.dialysate
                  .bicarbonate.isChecked,
              conductivity: getters.assessment.nurseryDiagnosis.medicInstruction
                .dialysate.conductivity.isChecked
                ? getters.assessment.nurseryDiagnosis.medicInstruction.dialysate
                    .conductivity.detail
                : '',
              temperature: getters.assessment.nurseryDiagnosis.medicInstruction
                .dialysate.temperature.isChecked
                ? Number(
                    getters.assessment.nurseryDiagnosis.medicInstruction
                      .dialysate.temperature.detail,
                  )
                : '',
            },
            profiling_prog: {
              na: getters.assessment.nurseryDiagnosis.medicInstruction
                .profilingProgram.na.isChecked
                ? getters.assessment.nurseryDiagnosis.medicInstruction
                    .profilingProgram.na.detail
                : '',
              uf: getters.assessment.nurseryDiagnosis.medicInstruction
                .profilingProgram.uf.isChecked
                ? getters.assessment.nurseryDiagnosis.medicInstruction
                    .profilingProgram.uf.detail
                : '',
              bicarbonate: getters.assessment.nurseryDiagnosis.medicInstruction
                .profilingProgram.bicarbonate.isChecked
                ? getters.assessment.nurseryDiagnosis.medicInstruction
                    .profilingProgram.bicarbonate.detail
                : '',
            },
            heparinitation: {
              circulation_dose: getters.assessment.nurseryDiagnosis
                .medicInstruction.insurance.circulationDose.isChecked
                ? Number(
                    getters.assessment.nurseryDiagnosis.medicInstruction
                      .insurance.circulationDose.detail,
                  )
                : '',
              initial_dose: getters.assessment.nurseryDiagnosis.medicInstruction
                .insurance.firstDose.isChecked
                ? Number(
                    getters.assessment.nurseryDiagnosis.medicInstruction
                      .insurance.firstDose.detail,
                  )
                : '',
              maintenance_dose: {
                cominue: getters.assessment.nurseryDiagnosis.medicInstruction
                  .insurance.maintenanceDose.continueDose.isChecked
                  ? Number(
                      getters.assessment.nurseryDiagnosis.medicInstruction
                        .insurance.maintenanceDose.continueDose.detail,
                    )
                  : '',
                interminuen: getters.assessment.nurseryDiagnosis
                  .medicInstruction.insurance.maintenanceDose.intermittenDose
                  .isChecked
                  ? Number(
                      getters.assessment.nurseryDiagnosis.medicInstruction
                        .insurance.maintenanceDose.intermittenDose.detail,
                    )
                  : '',
              },
              lmwh: getters.assessment.nurseryDiagnosis.medicInstruction
                .insurance.lmwh.isChecked
                ? getters.assessment.nurseryDiagnosis.medicInstruction.insurance
                    .lmwh.detail
                : '',
              without_heparin: getters.assessment.nurseryDiagnosis
                .medicInstruction.insurance.withoutInsurance.isChecked
                ? getters.assessment.nurseryDiagnosis.medicInstruction.insurance
                    .withoutInsurance.detail
                : '',
              rinse_program: getters.assessment.nurseryDiagnosis
                .medicInstruction.insurance.rinseProgram.isChecked
                ? getters.assessment.nurseryDiagnosis.medicInstruction.insurance
                    .rinseProgram.isAnHour
                : '',
            },
            notes: [
              {
                detail: getters.assessment.nurseryDiagnosis.note,
              },
            ],
            timestamps: {
              created_by: rootGetters.userLoggedIn.id,
            },
          },
        ],
        timestamps: {
          created_by: rootGetters.userLoggedIn.id,
        },
      };

      if (rootGetters.userLoggedIn.role.primary.toLowerCase() === 'casemix') {
        postPayload.medical_instruction[0] = {
          ...postPayload.medical_instruction[0],
          timestamps: {
            created_by: getters.assessment.timestampsDoctor.created_by_id,
            corrected_by: rootGetters.userLoggedIn.id,
            corrected_at: moment().format(),
          },
        };
      }
      return axios.post(
        Constant.apiUrl
          .concat('/patient/poly/9/2/')
          .concat(rootGetters.selectedPatient.id_queue)
          .concat('/1'),
        postPayload,
      );
    },
    postAssessmentCasemix({ getters, rootGetters }) {
      const postPayload = {
        isRefference: rootGetters.selectedPatient.isReferral || false,
        medical_instruction: [
          {
            complaint:
              getters.assessment.nurseryDiagnosis.medicInstruction.complaint,
            supporting_checkup:
              getters.assessment.nurseryDiagnosis.medicInstruction
                .supportCheckup,
            physical_checkup:
              getters.assessment.nurseryDiagnosis.medicInstruction
                .physicalCheckup,
            diagnostic:
              getters.assessment.nurseryDiagnosis.medicInstruction.diagnosis
                .filter((arr) => arr !== null)
                .map((data) => {
                  return data.icd && data.icd.id
                    ? {
                        _id: data.icd.id,
                        value: data.icd.value,
                        text: data.icd.text,
                        detail: data.detail,
                      }
                    : {
                        detail: data.detail,
                      };
                }),
            follow_up:
              getters.assessment.nurseryDiagnosis.medicInstruction.follow_up
                .filter((arr) => arr !== null)
                .map((data) => {
                  return data.icd && data.icd.id
                    ? {
                        _id: data.icd.id,
                        value: data.icd.value,
                        text: data.icd.text,
                        detail: data.detail,
                      }
                    : {
                        detail: data.detail,
                      };
                }),
            plan: getters.assessment.nurseryDiagnosis.medicInstruction.planning,
            recipe_hd:
              getters.assessment.nurseryDiagnosis.medicInstruction.hdRecipe
                .type === ''
                ? []
                : [
                    {
                      type: getters.assessment.nurseryDiagnosis.medicInstruction
                        .hdRecipe.type,
                      detail:
                        getters.assessment.nurseryDiagnosis.medicInstruction
                          .hdRecipe.type === 5
                          ? getters.assessment.nurseryDiagnosis.medicInstruction
                              .hdRecipe.detail
                          : '',
                    },
                  ],
            td: Number(getters.assessment.nurseryDiagnosis.medicInstruction.td),
            qb: Number(getters.assessment.nurseryDiagnosis.medicInstruction.qb),
            qd: Number(getters.assessment.nurseryDiagnosis.medicInstruction.qd),
            uf_goal: Number(
              getters.assessment.nurseryDiagnosis.medicInstruction.ufGoal,
            ),
            dialysate: {
              acetate:
                getters.assessment.nurseryDiagnosis.medicInstruction.dialysate
                  .acetate.isChecked,
              bicarbonate:
                getters.assessment.nurseryDiagnosis.medicInstruction.dialysate
                  .bicarbonate.isChecked,
              conductivity: getters.assessment.nurseryDiagnosis.medicInstruction
                .dialysate.conductivity.isChecked
                ? getters.assessment.nurseryDiagnosis.medicInstruction.dialysate
                    .conductivity.detail
                : '',
              temperature: getters.assessment.nurseryDiagnosis.medicInstruction
                .dialysate.temperature.isChecked
                ? Number(
                    getters.assessment.nurseryDiagnosis.medicInstruction
                      .dialysate.temperature.detail,
                  )
                : '',
            },
            profiling_prog: {
              na: getters.assessment.nurseryDiagnosis.medicInstruction
                .profilingProgram.na.isChecked
                ? getters.assessment.nurseryDiagnosis.medicInstruction
                    .profilingProgram.na.detail
                : '',
              uf: getters.assessment.nurseryDiagnosis.medicInstruction
                .profilingProgram.uf.isChecked
                ? getters.assessment.nurseryDiagnosis.medicInstruction
                    .profilingProgram.uf.detail
                : '',
              bicarbonate: getters.assessment.nurseryDiagnosis.medicInstruction
                .profilingProgram.bicarbonate.isChecked
                ? getters.assessment.nurseryDiagnosis.medicInstruction
                    .profilingProgram.bicarbonate.detail
                : '',
            },
            heparinitation: {
              circulation_dose: getters.assessment.nurseryDiagnosis
                .medicInstruction.insurance.circulationDose.isChecked
                ? Number(
                    getters.assessment.nurseryDiagnosis.medicInstruction
                      .insurance.circulationDose.detail,
                  )
                : '',
              initial_dose: getters.assessment.nurseryDiagnosis.medicInstruction
                .insurance.firstDose.isChecked
                ? Number(
                    getters.assessment.nurseryDiagnosis.medicInstruction
                      .insurance.firstDose.detail,
                  )
                : '',
              maintenance_dose: {
                cominue: getters.assessment.nurseryDiagnosis.medicInstruction
                  .insurance.maintenanceDose.continueDose.isChecked
                  ? Number(
                      getters.assessment.nurseryDiagnosis.medicInstruction
                        .insurance.maintenanceDose.continueDose.detail,
                    )
                  : '',
                interminuen: getters.assessment.nurseryDiagnosis
                  .medicInstruction.insurance.maintenanceDose.intermittenDose
                  .isChecked
                  ? Number(
                      getters.assessment.nurseryDiagnosis.medicInstruction
                        .insurance.maintenanceDose.intermittenDose.detail,
                    )
                  : '',
              },
              lmwh: getters.assessment.nurseryDiagnosis.medicInstruction
                .insurance.lmwh.isChecked
                ? getters.assessment.nurseryDiagnosis.medicInstruction.insurance
                    .lmwh.detail
                : '',
              without_heparin: getters.assessment.nurseryDiagnosis
                .medicInstruction.insurance.withoutInsurance.isChecked
                ? getters.assessment.nurseryDiagnosis.medicInstruction.insurance
                    .withoutInsurance.detail
                : '',
              rinse_program: getters.assessment.nurseryDiagnosis
                .medicInstruction.insurance.rinseProgram.isChecked
                ? getters.assessment.nurseryDiagnosis.medicInstruction.insurance
                    .rinseProgram.isAnHour
                : '',
            },
            notes: [
              {
                detail: getters.assessment.nurseryDiagnosis.note,
              },
            ],
            timestamps: {
              created_by: rootGetters.userLoggedIn.id,
            },
          },
        ],
        timestamps: {
          created_by: rootGetters.userLoggedIn.id,
        },
        detail: [
          {
            room_no: 'aisyiah10',
            machine_no: 'e15',
            dialisiser_type: 'oke berhasil',
            n_r: 'n dan r',
            medicine_alergic: {
              type: false,
              detail: '',
            },
            health_service: {
              primary_complaint:
                getters.assessment.mainComplaint.complaint.type.map((data) => {
                  return {
                    type: data,
                    detail:
                      data === '3'
                        ? getters.assessment.mainComplaint.complaint.detail
                        : '',
                  };
                }),
              pain: {
                type: getters.assessment.mainComplaint.pain === 1,
                level:
                  getters.assessment.mainComplaint.pain === 1
                    ? getters.assessment.mainComplaint.intensity.level
                    : 0,
                location:
                  getters.assessment.mainComplaint.pain === 1
                    ? getters.assessment.mainComplaint.location
                    : '',
                time: {
                  duration:
                    getters.assessment.mainComplaint.pain === 1
                      ? Number(getters.assessment.mainComplaint.time.duration)
                      : '',
                  type:
                    getters.assessment.mainComplaint.pain === 1
                      ? Number(getters.assessment.mainComplaint.time.type)
                      : '',
                },
                type2:
                  getters.assessment.mainComplaint.pain === 1
                    ? getters.assessment.mainComplaint.intensity.type
                    : 0,
              },
            },
            physical_checkup: {
              general_condition: {
                type: getters.assessment.physicalCheckup.generalCondition.value,
                detail:
                  getters.assessment.physicalCheckup.generalCondition.detail,
              },
              blood_pressure: [
                Number(getters.assessment.physicalCheckup.bloodPressure[0]),
                Number(getters.assessment.physicalCheckup.bloodPressure[1]),
              ],
              awareness: {
                type:
                  getters.assessment.physicalCheckup.conciousness.value === 1,
                detail: getters.assessment.physicalCheckup.conciousness.detail,
              },
              pulse: {
                type: getters.assessment.physicalCheckup.pulse.value === 1,
                freq: getters.assessment.physicalCheckup.pulse.detail,
              },
              respiration: {
                type: getters.assessment.physicalCheckup.respiration.value,
                freq: Number(
                  getters.assessment.physicalCheckup.respiration.detail,
                ),
              },
              conjunctiva: {
                type: getters.assessment.physicalCheckup.conjunctiva.value,
                detail: getters.assessment.physicalCheckup.conjunctiva.detail,
              },
              extrimities: getters.assessment.physicalCheckup.extrimities,
              weight: {
                dry_weight: Number(
                  getters.assessment.physicalCheckup.bodyWeight,
                ),
                pre_hd: Number(getters.assessment.physicalCheckup.weightPreHd),
                post_hd: Number(
                  getters.assessment.physicalCheckup.weightPostHd,
                ),
              },
              vascular_access: {
                type: Number(getters.assessment.physicalCheckup.vascularAccess),
              },
              hd_catheter: [
                {
                  type:
                    getters.assessment.physicalCheckup.hdCatheter === ''
                      ? ''
                      : Number(getters.assessment.physicalCheckup.hdCatheter),
                },
              ],
              fall_risk: {
                no_1: getters.assessment.physicalCheckup.fallHistory,
                no_2: getters.assessment.physicalCheckup.medicDiagnosis,
                no_3: getters.assessment.physicalCheckup.toolsHelper,
                no_4: getters.assessment.physicalCheckup.therapy,
                no_5: getters.assessment.physicalCheckup.wayOfWalking,
                no_6: getters.assessment.physicalCheckup.mentalStatus,
                summary: {
                  total_score: 0,
                  type: 0,
                },
              },
            },
            secondary_checkup:
              getters.assessment.firstNutritionScreening.support,
            initial_nutrition: {
              no1: {
                type: getters.assessment.firstNutritionScreening.weightDrop
                  .type,
                score:
                  getters.assessment.firstNutritionScreening.weightDrop.score,
              },
              no2:
                getters.assessment.firstNutritionScreening.foodIntakeDrop === 1,
            },
            psychosocial_history: {
              no1: getters.assessment.firstNutritionScreening.tradition === 1,
              no2: {
                type:
                  getters.assessment.firstNutritionScreening
                    .obstacleOfCommunication.type === 1,
                detail:
                  getters.assessment.firstNutritionScreening
                    .obstacleOfCommunication.type === 1
                    ? getters.assessment.firstNutritionScreening
                        .obstacleOfCommunication.detail
                    : '',
              },
              no3: {
                type:
                  getters.assessment.firstNutritionScreening.nurseAtHome
                    .type === 1,
                detail:
                  getters.assessment.firstNutritionScreening.nurseAtHome
                    .type === 1
                    ? getters.assessment.firstNutritionScreening.nurseAtHome
                        .detail
                    : '',
              },
              no4:
                getters.assessment.firstNutritionScreening.presentCondition !==
                null
                  ? [
                      {
                        type: getters.assessment.firstNutritionScreening
                          .presentCondition,
                      },
                    ]
                  : [],
            },
            praying_ability: {
              no1: {
                type: getters.assessment.firstNutritionScreening
                  .obligatoryPrayer.value,
                detail:
                  getters.assessment.firstNutritionScreening.obligatoryPrayer
                    .value === 2
                    ? getters.assessment.firstNutritionScreening
                        .obligatoryPrayer.detail
                    : '',
              },
              no2: getters.assessment.firstNutritionScreening.wudhu,
              no3: getters.assessment.firstNutritionScreening.sholat,
            },
            nursery_diagnostic: {
              complaint_type:
                getters.assessment.nurseryDiagnosis.diagnosis === ''
                  ? ''
                  : getters.assessment.nurseryDiagnosis.diagnosis.type.map(
                      (data) => {
                        return {
                          type: data,
                          detail:
                            data === 7
                              ? getters.assessment.nurseryDiagnosis.diagnosis
                                  .other
                              : '',
                        };
                      },
                    ),
              nursery_intervention_type:
                getters.assessment.nurseryDiagnosis.nurseryIntervention === ''
                  ? ''
                  : getters.assessment.nurseryDiagnosis.nurseryIntervention.type.map(
                      (data) => {
                        let detail = '';
                        if (data === 9) {
                          detail =
                            getters.assessment.nurseryDiagnosis
                              .nurseryIntervention.healthEducation;
                        } else if (data === 12) {
                          detail =
                            getters.assessment.nurseryDiagnosis
                              .nurseryIntervention.other;
                        }
                        return {
                          type: data,
                          detail,
                        };
                      },
                    ),
              collaboration_intervention:
                getters.assessment.nurseryDiagnosis
                  .collaborationIntervention === ''
                  ? ''
                  : getters.assessment.nurseryDiagnosis.collaborationIntervention.map(
                      (data) => {
                        return {
                          type: data,
                        };
                      },
                    ),
            },

            nurses_on_duty: !getters.assessment.nurseryDiagnosis.dataNurse
              ? ''
              : getters.assessment.nurseryDiagnosis.dataNurse.allSelected.map(
                  (data) => {
                    return data.id || data._id;
                  },
                ),
            timestamps: {
              created_by: rootGetters.userLoggedIn.id,
            },
            nurse_access_info: !getters.assessment.nurseryDiagnosis.dataNurse
              ? ''
              : getters.assessment.nurseryDiagnosis.dataNurse.select.id ||
                getters.assessment.nurseryDiagnosis.dataNurse.select._id,
          },
        ],
      };

      if (rootGetters.userLoggedIn.role.primary.toLowerCase() === 'casemix') {
        postPayload.medical_instruction[0] = {
          ...postPayload.medical_instruction[0],
          timestamps: {
            created_by: getters.assessment.timestampsDoctor.created_by_id,
            corrected_by: rootGetters.userLoggedIn.id,
            corrected_at: moment().format(),
          },
        };
        postPayload.detail[0] = {
          ...postPayload.detail[0],
          timestamps: {
            created_by: getters.assessment.timestampsNurse.created_by_id,
            corrected_by: rootGetters.userLoggedIn.id,
            corrected_at: moment().format(),
          },
        };
        postPayload.timestamps = {
          created_by: getters.assessment.timestampsDoctor.created_by_id,
          corrected_by: rootGetters.userLoggedIn.id,
          corrected_at: moment().format(),
        };
      }
      return axios.post(
        Constant.apiUrl
          .concat('/patient/poly/9/2/')
          .concat(rootGetters.selectedPatient.id_queue)
          .concat('/3'),
        postPayload,
      );
    },
    postMonitoring({ getters, rootGetters }) {
      return axios.post(
        Constant.apiUrl
          .concat('/patient/poly/9/3/')
          .concat(rootGetters.selectedPatient.id_queue)
          .concat('/')
          .concat(rootGetters.userLoggedIn.id),
        {
          detail_hd: getters.monitoring.monitoringData
            .filter((arr) => !arr.isPosted)
            .map((data) => {
              return {
                time: data.time.detail,
                qb: Number(data.qb),
                uf: Number(data.uf),
                blood_pressure: [
                  Number(data.bloodPressure[0]),
                  Number(data.bloodPressure[1]),
                ],
                pulse: Number(data.pulse),
                temperature: Number(data.temperature),
                respiration: Number(data.respiration),
                intake: {
                  nacl: Number(data.nacl),
                  dextrose: Number(data.dextrose),
                  eat_drink: Number(data.foodOrDrink),
                  other: Number(data.others),
                },
                uf_goal: Number(data.ufGoal),
                nurse: {
                  signature: rootGetters.userLoggedIn.id,
                  name: rootGetters.userLoggedIn.name,
                },
                other_information:
                  data.detail === ''
                    ? []
                    : [
                        {
                          detail: data.detail,
                        },
                      ],
                post_hd: data.time.type === 2,
                timestamps: {
                  created_by: rootGetters.userLoggedIn.id,
                },
              };
            }),
          total: [
            {
              total1: getters.monitoring.total,
              total2:
                getters.monitoring.totalUfGoal === ''
                  ? ''
                  : getters.monitoring.totalUfGoal,
              total_uf:
                getters.monitoring.totalUf === '-'
                  ? ''
                  : getters.monitoring.totalUf,
            },
          ],
          detail_disease: [
            {
              item:
                getters.monitoring.input.hdObstacle.length === 0
                  ? []
                  : getters.monitoring.input.hdObstacle.map((data) => {
                      return {
                        type: data,
                      };
                    }),
            },
          ],
          note_hd: [
            {
              data: [
                {
                  text:
                    getters.monitoring.input.dischargePlanning === ''
                      ? ''
                      : getters.monitoring.input.dischargePlanning,
                },
              ],
            },
          ],
        },
      );
    },
    updateMonitoring({ rootGetters }, item) {
      const detailHd = [item].map((data) => {
        return {
          time: data.time.detail,
          qb: Number(data.qb),
          uf: Number(data.uf),
          blood_pressure: [
            Number(data.bloodPressure[0]),
            Number(data.bloodPressure[1]),
          ],
          pulse: Number(data.pulse),
          temperature: Number(data.temperature),
          respiration: Number(data.respiration),
          intake: {
            nacl: Number(data.nacl),
            dextrose: Number(data.dextrose),
            eat_drink: Number(data.foodOrDrink),
            other: Number(data.others),
          },
          uf_goal: Number(data.ufGoal),
          nurse: {
            signature: rootGetters.userLoggedIn.id,
            name: rootGetters.userLoggedIn.name,
          },
          other_information:
            data.detail === ''
              ? []
              : [
                  {
                    detail: data.detail,
                  },
                ],
          post_hd: data.time.type === 2,
          timestamps: {
            created_by: rootGetters.userLoggedIn.id,
          },
        };
      });
      return axios.post(
        Constant.apiUrl
          .concat('/patient/poly/update/9/3/')
          .concat(rootGetters.selectedPatient.id_queue)
          .concat('/')
          .concat(rootGetters.userLoggedIn.id)
          .concat('/')
          .concat(item.key),
        {
          ...detailHd[0],
        },
      );
    },
  },
};
